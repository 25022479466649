@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Open+Sans:wght@400;500&family=Oswald:wght@300;400;500&family=Playfair+Display:ital,wght@0,500;0,600;1,400&family=Shalimar&family=Teko:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Oswald:wght@300;400;500&family=Playfair+Display:ital,wght@0,500;0,600;1,400&family=Shalimar&family=Teko:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Oswald:wght@300;400;500&family=Playfair+Display:ital,wght@0,500;0,600;1,400&family=Reem+Kufi&family=Shalimar&family=Teko:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=SolaimanLipi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Siyam+Rupali&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sahitya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Atma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

.element-font-1 {
  font-family: 'SolaimanLipi', sans-serif;
}
.font-all {
  font-family: 'Hind Siliguri', sans-serif;
}
.element-font-2 {
  font-family: 'Siyam Rupali', sans-serif;
}
.element-font-3 {
  font-family: 'Baloo Tamma 2', cursive;
}
.element-font-4 {
  font-family: 'Sahitya', serif;
}
.element-font-5 {
  font-family: 'Atma', serif;
}
.element-font-6 {
  font-family: 'Mukta', sans-serif;
}
.font-Oswald {
  font-family: 'Oswald', sans-serif;
}

.font-reem{
  font-family: 'Reem Kufi', sans-serif;
}

.sticky-nav {
    position: fixed;
    top: 0; left: 0;
    background-color: rgba(40, 53, 83, 0.923);
    transition: all linear 0.4s;
    color: #4ee4ff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    animation: scroll-header-animation 0.5s linear;
    box-shadow: 0px 2px 15px #0000000d;
    
}

/* ========= Water mark add result card */
.water-mark{
  position: relative;
}

.water-mark::before{
  content: '';
  position: absolute;
  top: 0; left: 0;
  background-image: url(./assets/images/log03.png);
  background-size: 500px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: .1;
  z-index: 2;
}

/* ===== scrollbar design css ===== */
::-webkit-scrollbar{
    width: 8px;
  }
  ::-webkit-scrollbar-track{
    background-color: #b75bf552;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #4c118391;
    border-radius: 20px;
  }

  /* animation header */
  @keyframes scroll-header-animation {
    from {
      top: -90px;
    }
  
    to {
      top: 0;
    }
  }



  @media screen and (max-width: 425px) {

    ::-webkit-scrollbar{
      width: 0px;
    }
 
  }