
.active{
    border-radius: 10px;
    background-color: #eaeaea;
    color: #057645;
}

.shadow-box{
    box-shadow: 0, 0, 0, 5px #000 ;
}
.l-height{
    line-height: -5px !important;
}

.hide-p{
    display: block !important;
}
.order{
    order: 2;
    color: #f6eaf9;
}

.p-collaps{
    padding: 0px !important;
}
.attendance-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
    grid-gap: 10px;
}

.reword-student-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
}

.smsUser-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, 21px);
}

.result-table table tr{
    border: 1px solid #f6eaf9;
}
.result-table table tr th{
    border: 1px solid #e7a2f9;
}
.result-table table thead{
    border: 1px solid #dd86f3;
    background-color: rgb(246, 234, 254);
}

/* Responsive design */
@media screen and (max-width: 480px) {
    .hide-p{
        display: none !important;
    }
    .fontSize{
        font-size: 24px;
        font-weight: 800;
    }
    .attendance-grid{
        grid-gap: 6px;
    }
    .reword-student-grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)) !important;
        grid-gap: 10px;
    }

  }